import React from 'react';
import { Box, Link, Text } from 'rebass';
import { titleList } from './css/positions.css';
import { ListItemSquare } from '../../../components/layout/ListItemComponents/ListItemSquare';
import { PageSection } from '../../../components/layout/_/PageSection/PageSection.component';
import SectionHeader from '../../../components/layout/SectionHeader/SectionHeader.component';
import { useTranslation } from 'react-i18next';
import { StandardPageLayout } from '../../../layouts/StandardPage.layout';

const NodeJsDeveloper: React.FC = () => {
  const { t } = useTranslation();

  const meta = {
    title: t('meta_CareerTitle'),
    description: t('meta_CareerDescription'),
  };

  return (
    <StandardPageLayout
      mainHeading={t('career_MainHeading')}
      backgroundImage="bg/career@3x.jpg"
      backgroundImageMobile="bg/career-mobile@3x.jpg"
      og={{
        image: '/img/meta-img/career-meta-og-2-0-new.jpeg',
      }}
      meta={meta}
    >
      <PageSection paddingBottom={30}>
        <SectionHeader title="Node.js DEVELOPER - Senior" alignItems="start" />
        <Box pl={{ _: '9px', md: '0px' }}>
          <Text mt={'30px'} mb={'10px'} css={titleList} textAlign="left">
            The profile we are looking for:
          </Text>
          <ListItemSquare text={'Full-time frontend software engineer'} />
          <ListItemSquare text={'3-5 years of experience'} />
          <ListItemSquare text={'Excellent knowledge of JavascriptES6+, Typescript'} />
          <ListItemSquare text={'Experience with NodeJS'} />
          <ListItemSquare text={'Docker, Continuous deployment process'} />
          <ListItemSquare text={'Experience with software architecture -- modelling software before it goes into the coding phase'} />
          <ListItemSquare
            text={
              'Strong experience in interacting with MySQL database systems through abstraction layer (in addition to the ability to write raw SQL queries)'
            }
          />
          <ListItemSquare text={'Design and implementation of low-latency, high-availability, and performant applications.'} />
          <ListItemSquare text={'Proficient in using Git Version Control'} />
          <ListItemSquare text={'Strong knowledge of:'} />
          <p style={{ marginLeft: '30px', fontWeight: '200' }}>- OO principles</p>
          <p style={{ marginLeft: '30px', fontWeight: '200' }}>- Functional paradigm</p>
          <p style={{ marginLeft: '30px', fontWeight: '200' }}>- Functional reactive paradigm</p>
          <p style={{ marginLeft: '30px', fontWeight: '200' }}>- Software design patterns</p>
          <ListItemSquare
            text={'Strong knowledge of underlying systems, protocols and web platforms (Http protocol, OAuth/OAuth2, JWT, ...)'}
          />
          <ListItemSquare text={'Bonus'} />
          <p style={{ marginLeft: '30px', fontWeight: '200' }}>- Degree in CS university studies or equivalent/related fields</p>
          <p style={{ marginLeft: '30px', fontWeight: '200' }}>- Experience with any testing framework (Jasmine, Jest, Karma, Mocha ...)</p>
          <p style={{ marginLeft: '30px', fontWeight: '200' }}>- Firebase</p>

          <Text mt={'30px'} mb={'10px'} css={titleList} textAlign="left">
            What we offer:
          </Text>
          <ListItemSquare text={'Fast-paced growing startup'} />
          <ListItemSquare text={'Only in-house projects'} />
          <ListItemSquare
            text={
              'Dedication and focus (you will not be exposed to multitasking, instead, you will mostly work on one, up to two projects during one week)'
            }
          />
          <ListItemSquare text={'Chance to learn new stuff every now and then'} />
          <ListItemSquare text={'Ability to explore new tools, programming languages, new ways to solve old problems'} />

          <Text mt={'30px'} mb={'10px'} css={titleList} textAlign="left">
            These are the skills you need to be great at this job and will be evaluated on:
          </Text>
          <ListItemSquare text={'Strong problem-solving and analytical skills'} />
          <ListItemSquare text={'Attention to details'} />
          <ListItemSquare text={'Patience to solve problems the right way'} />
          <ListItemSquare text={'Desire to learn'} />
          <ListItemSquare text={'Rigorous understanding and appreciation of coding best practices'} />
          <ListItemSquare text={'Accountability and dependability'} />

          <Text mt={'30px'} mb={'10px'} css={titleList} textAlign="left">
            This is what we value:
          </Text>
          <ListItemSquare text={'Open, direct, and clear communication'} />
          <ListItemSquare text={'Sharing - knowledge, enthusiasm, dilemmas, ideas '} />
          <ListItemSquare text={'Curiosity and initiative'} />
          <ListItemSquare text={'We value and are proud of our product and its mission and our people making it grow'} />
        </Box>
        <Text mt={4}>
          If you find us inspiring or know someone who would be a good fit, feel free to contact us at {''}
          <Link href="mailto:hr@vozzi.eu">hr@vozzi.eu</Link>
        </Text>
      </PageSection>
    </StandardPageLayout>
  );
};

export default NodeJsDeveloper;
